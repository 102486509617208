import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import Swiper from 'react-id-swiper'
import { Pagination } from 'swiper'

import Markdown from 'components/Markdown'
import { Container, Row, Column } from 'components/Grid'

import Section from './Section'
import Video from './Video'

class Videos extends Component {
   constructor(props) {
      super(props)

      this.state = {
         slider: false,
      }
   }

   componentDidMount() {
      this.breakpoint = window.matchMedia('(min-width:768px)')

      if (this.breakpoint.matches) {
         this.setState({ slider: false })
      } else {
         this.setState({ slider: true })
      }

      this.breakpoint.addListener(this.handleMatchMedia)
   }

   componentWillUnmount() {
      this.breakpoint.removeListener(this.handleMatchMedia)
   }

   handleMatchMedia = (event) => {
      if (event.matches) {
         this.setState({ slider: false })
      } else {
         this.setState({ slider: true })
      }
   }

   render() {
      const { videosHeading, videosList, headingAlign, videosPerRow, showDescription } = this.props
      const { slider } = this.state
      const _videosPerRow = videosPerRow === 1 ? '100%' : videosPerRow === '2' ? '50%' : '33.33%'

      const params = {
         modules: [Pagination],
         pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
         },
      }

      return (
         <Section id="videoListSection" className="videoListSection">
            <Container>
               <Row>
                  <StyledColumn align={headingAlign}>
                     {!isEmpty(videosHeading) && !isEmpty(videosHeading.markdown) && (
                        <Markdown className="heading">{videosHeading.markdown}</Markdown>
                     )}
                  </StyledColumn>
                  <Column>
                     {slider ? (
                        <Swiper {...params}>
                           {!isEmpty(videosList) && videosList.map((item) => <Video {...item} key={item.id} />)}
                        </Swiper>
                     ) : (
                        <Row>
                           {!isEmpty(videosList) &&
                              videosList.map((item) => (
                                 <Column key={item.id} width={[1, 1 / 2, _videosPerRow]} className="video-wrapper">
                                    <Video showDescription={showDescription} {...item} />
                                 </Column>
                              ))}
                        </Row>
                     )}
                  </Column>
               </Row>
            </Container>
         </Section>
      )
   }
}

export const query = graphql`
   fragment VideosData on ContentfulPartialVideos {
      id
      videosHeading {
         markdown: videosHeading
      }
      headingAlign
      videosPerRow
      showDescription
      videosList {
         id
         textCenter
         content {
            markdown: childMarkdownRemark {
               html
            }
         }
      }
   }
`

export default Videos

const StyledColumn = styled(Column)`
   & .heading {
      text-align: ${({ align }) => align};
      position: relative;
      margin-bottom: 37px;

      p {
         font-size: 14px;
         font-weight: normal;
         letter-spacing: normal;
         text-transform: inherit;
      }

      .viewAll {
         font-size: 18px;
         position: absolute;
         right: 0;
         top: 50%;
         transform: translateY(-50%);
      }
   }
`
