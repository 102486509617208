import React from 'react'
import styled, { css } from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import Markdown from 'components/Markdown'

import media from 'utils/media'

const VideoComponent = ({ textCenter, content, className = '', showDescription }) => {
   return (
      <StyledVideo showDescription={showDescription} center={textCenter} className={className || undefined}>
         {!isEmpty(content) && !isEmpty(content.markdown) && <Markdown>{content.markdown.html}</Markdown>}
      </StyledVideo>
   )
}

export default VideoComponent

const StyledVideo = styled.div`
   margin: 50px 0 73px;
   text-align: ${({ center }) => (center ? 'center' : 'left')};

   ${({ showDescription }) =>
      !showDescription &&
      css`
         text-align: left;

         ${media.greaterThan('lg')`
        & .youtube-box {
          margin: 0 0 20px !important;
         }
      `};
      `}

   ${media.greaterThan('md')`
    margin: 0 0 31px;
  `};

   ${media.greaterThan('lg')`
    margin: 0 0 37px;
  `};

   & > div {
      ${media.greaterThan('sm')`
      width: 80%;
      margin: 0 auto;
    `};

      ${media.greaterThan('md')`
      width: 100%;
      margin: 0;
    `};

      h4 {
         font-family: ${({ theme }) => theme.fonts.heldane_text};
         margin-bottom: 5px;

         ${({ showDescription }) =>
            !showDescription &&
            css`
               padding: 0 !important;
            `}

         ${media.greaterThan('lg')`
        padding: 0 30px;
      `};

         ${media.greaterThan('xl')`
        padding: 0 50px;
      `};
      }

      p {
         display: ${({ showDescription }) => (showDescription ? 'block' : 'none')};
         font-size: 14px;
         line-height: 1.71;
         letter-spacing: 0.3px;

         ${media.greaterThan('lg')`
        font-size: 16px;
        line-height: 1.5;
        padding: 0 30px;
      `};

         ${media.greaterThan('xl')`
        padding: 0 80px;
      `};

         &:last-child {
            margin-bottom: 0;
         }
      }

      .time {
         color: ${({ theme }) => theme.colors.textColor};
         font-size: 14px;
         position: relative;
         top: -5px;
      }
   }
`
