import styled from 'styled-components'

import media from 'utils/media'

const Section = styled.section`
   padding-top: 30px;
   padding-bottom: 50px;

   ${media.greaterThan('md')`
    padding-top: 51px;
    padding-bottom: 0px;
  `};

   ${media.greaterThan('lg')`
    padding-top: 95px;
  `};

   .heading {
      text-align: center;

      ${media.greaterThan('md')`
      margin-bottom: 52px;
    `};

      ${media.greaterThan('lg')`
      margin-bottom: 71px;
    `};

      h2 {
         color: ${({ theme }) => theme.colors.textColor};
         margin-bottom: 0;
      }

      p {
         font-size: 12px;
         line-height: 2.67;
         letter-spacing: 1.5px;
         text-transform: uppercase;

         &:last-child {
            margin-bottom: 0;
         }
      }
   }
`

export default Section
